export default {
  MAP: {
    SETTINGS: {
      apiKey: "71bdfbbb-9866-4c00-82e4-af4fdcbfd3ec",
      lang: "ru_RU",
      coordorder: "latlong",
      enterprise: false,
      version: "2.1",
    },
  },
  ROLE: {
    superuser: "Администратор системы",
    admin: "Администратор компании",
    head: "Начальник",
    /*operational_duty: "Оперативный дежурный",
    producer: "Производитель",*/
    warehouseman: "Кладовщик",
    ptd: "ПТО",
    cpu: "ЦПУ",
    director: "Директор",
    engineer: "Инженер",
    performer: "Исполнитель",
  },
  MESSAGE: {
    SUCCESS: "Успешно.",
    ERROR: {
      NOT_FOUND:
        "Элемент не найден.<br />Обратитесь к администратору за дополнительными сведениями.",
      SERVER:
        "Произошла ошибка.<br />Обратитесь к администратору за дополнительными сведениями.",
      FORM: "Заполните все необходимые поля.",
      FIX: "Исправьте все необходимые поля.",
      LOGIN: "Неправильный логин или пароль.",
      BLOCK:
        "Учетная запись заблокирована. Пожалуйста, обратитесь к администратору.",
    },
  },
  WEEKDAY: {
    SHORT: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    FULL: [
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
      "Воскресенье",
    ],
  },
  MODULES: {
    WPP: {
      ROLE: {
        lead_engineer: "Ведущий инженер",
        engineer: "Инженер",
        electrician: "Электрослесарь",
        electrician_underground: "Подземный электрослесарь",
        technician: "Техник",
        locksmith: "Слесарь",
      },
      WORK_TYPE: {
        electrical_installation_work: "Работа в электроустановках",
        high_risk_work: "Работа повышенной опасности",
      },
    },
    WORK_ORDER: {
      STATUS: {
        new: "Новый",
        in_progress: "В работе",
        rescheduled: "Перенесен",
        finished: "Завершен",
        canceled: "Отменен",
      },
      STATUS_CLASS: {
        new: "blue--text",
        in_progress: "orange--text",
        rescheduled: "blue--text",
        finished: "green--text",
        canceled: "red--text",
        failed: "red--text",
      },
    },
    WORK_ORDER2: {
      STATUS: {
        new: {
          text: "Новый",
          value: "new",
        },
        confirm: {
          text: "Подтвержден",
          value: "confirm",
        },
        reject: {
          text: "Отклонен",
          value: "reject",
        },
        in_progress: {
          text: "В работе",
          value: "in_progress",
        },
        pause: {
          text: "Приостановлен",
          value: "pause",
        },
        canceled: {
          text: "Отменен",
          value: "canceled",
        },
        finished: {
          text: "Завершен",
          value: "finished",
        },
      },
    },
    SPARE_PART: {
      spare_part: "Запчасть",
      component: "Узел",
      consumable: "Расходник",
    },
    DEFECT: {
      TYPE: {
        critical: "Предельное",
        unworkable: "Неработоспособное",
        workable: "Работоспособное",
      },
      STATUS: {
        not_confirmed: "Не подтвержден",
        defective: "Неисправен",
        fixed: "Исправлен",
      },
      STATUS_CLASS: {
        fixed: "green--text",
        defective: "red--text",
      },
      RISK_OF_FAILURE: {
        low: "Низкий",
        middle: "Средний",
        high: "Высокий",
      },
    },
    REGULATION: {
      PERIOD: {
        daily: "Ежедневно",
        weekly: "Еженедельно",
        monthly: "Ежемесячно",
      },
      PERIODICITY: {
        min30: "Каждые 30 минут",
        hour1: "Каждый 1 час",
        hour1_min30: "Каждые 1,5 часа",
        hour2: "Каждые 2 часа",
        hour2_min30: "Каждые 2,5 часа",
        hour3: "Каждые 3 часа",
        hour3_min30: "Каждые 3,5 часа",
        hour4: "Каждые 4 часа",
      },
      UNIT: [
        "N*m",
        "µm",
        "Ампер",
        "Атмосфер",
        "Бар",
        "ВАТТ",
        "Вольт",
        "Герц (ГЦ)",
        "Грамм",
        "Давление (Pa)",
        "Кг*С",
        "Килограмм",
        "Комплект",
        "Л.С.",
        "Метр",
        "Миллиметр",
        "Минут",
        "Моточасы",
        "ОМ",
        "Об/мин",
        "Оборот",
        "Процент (%)",
        "С - градус цельсий",
        "Сантиметр",
        "Секунд",
        "Тонн",
        "Часов",
        "Штук",
        "дм3",
        "литр",
        "м2",
        "м3",
        "мм2",
        "мм3",
        "см2",
        "см3",
      ],
    },
    AGGREGATE_JOURNAL: {
      SOURCE: {
        defect: "Дефект",
        work_order: "Наряд",
        regulation: "Регламент",
      },
    },
    MONITORING: {
      TYPE: [
        {
          id: "skip",
          title: "Количество скипов",
          order: 0,
        },
        {
          id: "ore",
          title: "Отсутствие руды",
          order: 1,
        },
        {
          id: "bunker",
          title: "Бункера заполнены",
          order: 2,
        },
        {
          id: "jam",
          title: "Пробка в скипу, рудоспуске, дозаторе",
          order: 3,
        },
        {
          id: "delay",
          title: "Задержка, связанная со спуском/подъемом клети",
          order: 4,
        },
        {
          id: "shift_change",
          title: "Пересменка, проверка защит",
          order: 5,
        },
        {
          id: "defect",
          title: "Неисправность оборудования ШПМ",
          order: 6,
        },
        {
          id: "downtime",
          title:
            "Простои связанные с нарушением снабжения электро и пневмоэнергией, состоянием ствола",
          order: 7,
        },
        {
          id: "failure",
          title:
            "Отказ оборудования в результате его неправильной эксплуатации",
          order: 8,
        },
      ],
    },
    SAFETY: {
      VIOLATION: {
        STATUS: {
          new: "Новый",
          finished: "Исправлено",
        },
        STATUS_CLASS: {
          new: "blue--text",
          finished: "green--text",
        },
      },
    },
    REQUEST: {
      STATUS: {
        open: {
          text: "Открыта",
          value: "open",
          color: "#FF3B2F",
          bgColor: "#FFEBEA",
        },
        contractor_appointed: {
          text: "Подрядчик назначен",
          value: "contractor_appointed",
          color: "#460092",
          bgColor: "#EEDAFF",
        },
        contractor_accept: {
          text: "Принята подрядчиком",
          value: "contractor_accept",
          color: "#BC00D9",
          bgColor: "#FFEBFE",
        },
        at_performer: {
          text: "Назначен исполнитель",
          value: "at_performer",
          color: "#BE6500",
          bgColor: "#FFE4CD",
        },
        in_progress: {
          text: "В работе",
          value: "in_progress",
          color: "#CF8700",
          bgColor: "#FFEFD2",
        },
        under_review: {
          text: "На проверке",
          value: "under_review",
          color: "#1976D2",
          bgColor: "#DAEAFF",
        },
        postponed: {
          text: "Отложена",
          value: "postponed",
          color: "#757575",
          bgColor: "#FAFAFA",
        },
        completed: {
          text: "Выполнен",
          value: "completed",
          color: "#1234E1",
          bgColor: "#C8D7FF",
        },
        rejected: {
          text: "Отклонена",
          value: "rejected",
          color: "#757575",
          bgColor: "#FAFAFA",
        },
        closed: {
          text: "Закрыта",
          value: "closed",
          color: "#04A856",
          bgColor: "#EFFFF7",
        },
      },
    },
    SERVICE_ACT: {
      STATUS: {
        draft: {
          text: "Черновик",
          value: "draft",
          color: "#CF8700",
          bgColor: "#FFEFD2",
        },
        formed: {
          text: "Сформирован АКТ",
          value: "formed",
          color: "#1976D2",
          bgColor: "#DAEAFF",
        },
      },
    },
    CLIENT: {
      ROLES: {
        client: { text: "Клиент", value: "client" },
        contractor: { text: "Подрядчик", value: "contractor" },
      },
    },
  },
  NOTIFICATION: {
    pages: {
      defect: "/defect",
      history: "/regulation/history",
    },
  },
};
